import Onboarding from "src/assets/images/onboarding.png";

const OnboardingVerification = () => {
  return (
    <div className="min-h-screen min-w-full max-w-full">
      <main className="overflow-x-hidden h-screen">
        <div className="hidden lg:block p-4 text-xl font-medium bg-[#2C2B2A] text-white">
          Verificação de identidade
        </div>
        <section className="h-full flex flex-col items-center p-6 justify-between text-center">
          <div className="p-6 mt-10 flex flex-col lg:flex-row gap-10 lg:gap-20 items-center md:mt-20">
            <div className="max-w-md lg:max-w-xl">
              <img src={Onboarding} alt="Conta digital" />
            </div>
            <div className="lg:text-left max-w-md">
              <h1 className="font-medium text-2xl mb-10 lg:mb-5">
                Agora é só aguardar!
              </h1>
              <div className="space-y-6 lg:space-y-1">
                <p>
                  Estamos analisando sua foto e documentos, este processo pode
                  levar até 48h após o envio.{" "}
                  <span className="hidden lg:inline">
                    Assim que a análise for concluída, informaremos por e-mail.
                  </span>
                </p>
                <p className="lg:hidden">
                  Assim que a análise for concluída, informaremos por e-mail.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OnboardingVerification;
